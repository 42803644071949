import { Alert, Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';

import image3 from 'assets/img/help/advanced/structure-ev2.png';
import image4 from 'assets/img/help/advanced/structure-ev3.png';

import '../../style.css';

const { Text } = Typography;

function RequirementsTable(props) {
  const { leftColRequired, leftNote, leftImage, rightColRequired, rightImage } = props;

  return (
    <Row justify={'center'}>
      <Col md={{ span: 16 }} xs={{ span: 24 }}>
        <div className="help-table">
          <Row justify="center">
            {/* Left column */}
            <Col md={{ span: 12 }} xs={{ span: 24 }}>
              <div className="help-table-title">
                <Text>Sin SKU</Text>
              </div>
              <div className="help-table-first-cell">
                <Text>
                  Si en tu archivo de demandas no ingresas SKU estas columnas o campos son
                  obligatorios (*).
                </Text>
              </div>
              <div className="help-table-item">
                {leftImage && (
                  <Col>
                    <img
                      src={image3}
                      alt={'img3'}
                      className="help-structure-table-image help-table-image1"
                    />
                  </Col>
                )}

                <ul>
                  {leftColRequired.map((item, index) => {
                    return (
                      <li key={index}>
                        <Text>{item} (*Obligatorio)</Text>
                      </li>
                    );
                  })}
                </ul>
                {leftNote && (
                  <Alert
                    type="info"
                    description={
                      <>
                        <Text strong>Nota: </Text>
                        <Text>{leftNote}</Text>
                      </>
                    }
                  />
                )}
                <br />
                <Text justify="center" style={{ color: '#727272' }}>
                  Opcionales
                </Text>
                <ul>
                  <li>
                    <Text>Información adicional</Text>
                  </li>
                  <li>
                    <Text>Documento</Text>
                  </li>
                  <li>
                    <Text>VIP</Text>
                  </li>
                  <li>
                    <Text>Código de contacto</Text>
                  </li>
                  <li>
                    <Text>Nombre de contacto</Text>
                  </li>
                  <li>
                    <Text>Teléfono</Text>
                  </li>
                  <li>
                    <Text>Email</Text>
                  </li>
                  <li>
                    <Text>Descripción</Text>
                  </li>
                  <li>
                    <Text>Cantidad</Text>
                  </li>
                  <li>
                    <Text>Cargas de producto</Text>
                  </li>
                  <li>
                    <Text>Camión máximo admisible</Text>
                  </li>
                  <li>
                    <Text>Camión mínimo admisible</Text>
                  </li>
                  <li>
                    <Text>Grupo</Text>
                  </li>
                  <li>
                    <Text>Prioridad</Text>
                  </li>
                  <li>
                    <Text>Hora de inicio de colación</Text>
                  </li>
                  <li>
                    <Text>Hora de fin de colación</Text>
                  </li>
                  <li>
                    <Text>Inicio ventana de atención</Text>
                  </li>
                  <li>
                    <Text>Fin ventana de atención</Text>
                  </li>
                  <li>
                    <Text>Tiempo atención cliente</Text>
                  </li>
                </ul>
              </div>
            </Col>
            {/* Right column */}
            <Col md={{ span: 12 }} xs={{ span: 24 }}>
              <div className="help-table-title help-table-right-cell help-table-right-title">
                <Text>Con SKU</Text>
              </div>
              <div className="help-table-first-cell help-table-right-cell">
                <Text>
                  Si en tu archivo de demandas ingresas SKU estas columnas o campos son obligatorios
                  (*).
                </Text>
              </div>
              <div className="help-table-item help-table-right-cell">
                {rightImage && (
                  <Col justify="center">
                    <img
                      src={image4}
                      alt={'img3'}
                      className="help-structure-table-image help-table-image2"
                    />
                  </Col>
                )}
                <ul>
                  {rightColRequired.map((item, index) => {
                    return (
                      <li key={index}>
                        <Text>{item} (*Obligatorio)</Text>
                      </li>
                    );
                  })}
                </ul>
                <Alert
                  type="info"
                  description={
                    <>
                      <Text strong>Nota:</Text> <br />
                      <ul className="help-ol">
                        <li>
                          <Text>
                            Al ingresar las cargas de producto automaticamente se completan las
                            cargas generales.
                          </Text>
                        </li>
                        {leftNote && (
                          <li>
                            <Text>{leftNote}</Text>
                          </li>
                        )}
                      </ul>
                    </>
                  }
                />
                <br />
                <Text justify="center" style={{ color: '#727272' }}>
                  Opcionales
                </Text>
                <ul>
                  <li>
                    <Text>Información adicional</Text>
                  </li>
                  <li>
                    <Text>Documento</Text>
                  </li>
                  <li>
                    <Text>VIP</Text>
                  </li>
                  <li>
                    <Text>Código de contacto</Text>
                  </li>
                  <li>
                    <Text>Nombre de contacto</Text>
                  </li>
                  <li>
                    <Text>Teléfono</Text>
                  </li>
                  <li>
                    <Text>Email</Text>
                  </li>
                  <li>
                    <Text>Camión máximo admisible</Text>
                  </li>
                  <li>
                    <Text>Camión mínimo admisible</Text>
                  </li>
                  <li>
                    <Text>Grupo</Text>
                  </li>
                  <li>
                    <Text>Prioridad</Text>
                  </li>
                  <li>
                    <Text>Hora de inicio de colación</Text>
                  </li>
                  <li>
                    <Text>Hora de fin de colación</Text>
                  </li>
                  <li>
                    <Text>Inicio ventana de atención</Text>
                  </li>
                  <li>
                    <Text>Fin ventana de atención</Text>
                  </li>
                  <li>
                    <Text>Tiempo atención cliente</Text>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}

RequirementsTable.defaultProps = {
  leftColRequired: [],
  leftImage: false,
  leftNote: '',

  rightColRequired: [],
  rightImage: false,
};

RequirementsTable.propTypes = {
  leftColRequired: PropTypes.arrayOf(PropTypes.string),
  leftImage: PropTypes.bool,
  leftNote: PropTypes.string,

  rightColRequired: PropTypes.arrayOf(PropTypes.string),
  rightImage: PropTypes.bool,
};

export default RequirementsTable;
