import { Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';

import IntroComponent from 'components/Help/Detail/common/introComponent';

const { Text } = Typography;

function RequirementsAdvanced(props) {
  const { downloadExampleFile, helpBreadcrumbs } = props;
  const mainText = ' Si decides subir una demanda de tipo avanzada';

  return (
    <>
      {helpBreadcrumbs()}
      <IntroComponent
        title="Requisitos del formato avanzado"
        type="advanced"
        mainText={mainText}
        downloadExampleFile={downloadExampleFile}
      />
      <Row gutter={[12, 12]} justify={'center'}>
        <Col md={{ span: 16 }} xs={{ span: 24 }}>
          <ol className="help-ol">
            <li className="help-li">
              <Text strong>Código de visita: </Text>
              <Text>En formato numérico y texto (alfanumérico).</Text>
            </li>
            <li className="help-li">
              <Text strong>Nombre: </Text>
              <Text>
                Texto libre, recuerda que esta información se reflejará en la plataforma y la app
                Routing Mobile.
              </Text>
            </li>
            <li className="help-li">
              <Text strong>Latitud: </Text>
              <Text>En formato numérico con comas. Ejemplo: “-33,454994”.</Text>
            </li>
            <li className="help-li">
              <Text strong>Longitud: </Text>
              <Text>En formato numérico con comas. Ejemplo: “-70,648754”.</Text>
            </li>
            <li className="help-li">
              <Text strong>Cargas: </Text>
              <Text>
                El valor es numérico o decimal. En el caso de que coloques una carga en decimal,
                recuerda que lo tienes que separar con una (,). Ejemplo: 12,3.
              </Text>
              <br />
              <Text strong>Nota: </Text>
              <Text>La carga 1 es obligatoria* y las demás son opcionales.</Text>
            </li>
            <li className="help-li">
              <Text strong>País: </Text>
              <Text>Formato texto.</Text>
            </li>
            <li className="help-li">
              <Text strong>Región o Provincia: </Text>
              <Text>Formato texto, relacionado con el país.</Text>
            </li>
            <li className="help-li">
              <Text strong>Comuna: </Text>
              <Text>Formato texto, relacionado con la región o provincia.</Text>
            </li>
            <li className="help-li">
              <Text strong>Calle: </Text>
              <Text>
                Texto libre. Este dato será el que usará la plataforma para ubicar la visita, asi
                que asegurate que esté lo más exacta posible.
              </Text>
            </li>
            <li className="help-li">
              <Text strong>Número: </Text>
              <Text>
                Formato numérico. Este dato será el que usará la plataforma para ubicar la visita,
                asi que asegurate que esté bien escrita la información.
              </Text>
            </li>
            <li className="help-li">
              <Text strong>Información adicional: </Text>
              <Text>Texto libre.</Text>
            </li>
            <li className="help-li">
              <Text strong>Documento: </Text>
              <Text>Formato numérico y texto (alfanumérico).</Text>
            </li>
            <li className="help-li">
              <Text strong>VIP: </Text>
              <Text>
                Valores válidos (Si y No). Estos hacen referencias a visitas privilegiadas pero no
                afecta y no tiene nada que ver con la ventana de atención.
              </Text>
              <br />
              <Text>Recuerda pasar por priorizar.</Text>
            </li>
            <li className="help-li">
              <Text strong>Código de contacto: </Text>
              <Text>Formato numérico.</Text>
            </li>
            <li className="help-li">
              <Text strong>Nombre de contacto: </Text>
              <Text>Texto libre.</Text>
            </li>
            <li className="help-li">
              <Text strong>Teléfono </Text>
              <Text italic>(opcional)</Text>
              <Text>
                : Para mejores resultados, ingresar con el formato código país + teléfono y entre
                comillas. Ejemplo: “+56911111111” o “+56111111111”.
              </Text>
            </li>
            <li className="help-li">
              <Text strong>Email </Text>
              <Text italic>(opcional)</Text>
              <Text>: El formato del email es, por ejemplo, aa@bb.com.</Text>
            </li>
            <li className="help-li">
              <Text strong>SKU: </Text>
              <Text>Formato numérico y texto (alfanumérico).</Text>
            </li>
            <li className="help-li">
              <Text strong>Descripción: </Text>
              <Text>Texto libre.</Text>
            </li>
            <li className="help-li">
              <Text strong>Cantidad: </Text>
              <Text>Formato numérico.</Text>
            </li>
            <li className="help-li" size="small">
              <Text strong>Cargas del producto: </Text>
              <Text>
                El valor es numérico y decimal. En el caso de que coloques una carga en decimal,
                recuerda que lo tienes que separar con una (,). Ejemplo: 12,3.
              </Text>
              <br />
              <Text strong>Nota: </Text>
              <Text>La carga 1 es obligatoria* y las demás son opcionales.</Text>
              <br />
              <Text style={{ color: '#727272' }}>
                Si en las restricciones están activadas se tomará en cuenta los datos
              </Text>
            </li>
            <li className="help-li">
              <Text strong>Camión máximo admisible: </Text>
              <Text>El formato es numérico.</Text>
            </li>
            <li className="help-li">
              <Text strong>Camión mínimo admisible: </Text>
              <Text>El formato es numérico.</Text>
            </li>
            <li className="help-li">
              <Text strong>Grupo: </Text>
              <Text>El formato es numérico y texto (alfanumérico).</Text>
            </li>
            <li className="help-li">
              <Text strong>Prioridad: </Text>
              <Text>El formato es numérico.</Text>
            </li>
            <li className="help-li">
              <Text strong>Hora de inicio de colación: </Text>
              <Text>El formato es hh:mm.</Text>
            </li>
            <li className="help-li">
              <Text strong>Hora fin de colación: </Text>
              <Text>El formato es hh:mm.</Text>
            </li>
            <li className="help-li">
              <Text strong>Inicio ventana de atención: </Text>
              <Text>El formato es hh:mm.</Text>
            </li>
            <li className="help-li">
              <Text strong>Fin ventana de atención: </Text>
              <Text>El formato es hh:mm.</Text>
            </li>
            <li className="help-li">
              <Text strong>Tiempo atención cliente: </Text>
              <Text>El formato es numérico.</Text>
            </li>
          </ol>
        </Col>
      </Row>
    </>
  );
}

RequirementsAdvanced.defaultProps = {
  downloadExampleFile: () => {},
  helpBreadcrumbs: () => {},
};

RequirementsAdvanced.propTypes = {
  downloadExampleFile: PropTypes.func,
  helpBreadcrumbs: PropTypes.func,
};

export default RequirementsAdvanced;
